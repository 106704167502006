.addressWrapper {
  border-left: 10px solid var(--jsyellownew);
  padding: 1rem;
  padding-left: 3rem;
  width: 75%;
  min-height: 250px;
  max-width: 450px;
  box-shadow: var(--shadowLight);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transform: rotate(-2deg);
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    box-shadow: var(--shadow);
    transform: rotate(0);
  }

  @media only screen and(max-width: 1024px) {
    padding-left: 1.5rem;
  }

  //&::after {
  //  content: '';
  //  position: absolute;
  //  right: 50%;
  //  top: -142px;
  //  width: 141px;
  //  height: 1000px;
  //  background: var(--jsyellow);
  //  transform: rotate(45deg);
  //  box-shadow: var(--shadow);
  //}

  * {
    text-align: left;
    text-decoration: none;
    color: #111111;
    z-index: 1;
  }

  a {
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 90%;
      height: 7px;
      z-index: -1;
      background-color: var(--jsyellownew);
      transform: scaleX(0);
      transform-origin: left;
      transition: all 0.2s;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  p {
    line-height: 1px;
    margin: 0;
    margin-bottom: 1rem;

  }

  h3 {
    position: relative;
    text-align: left;
    display: inline-block;
    z-index: 1;
    word-break: break-word;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -5px;
      width: 95%;
      height: 10px;
      background-color: var(--jsyellownew);
      z-index: -1;
    }
  }
}

.icon {
  width: 40px;
  display: inline-block;
}

.homepage {
  position: absolute;
  bottom: 5px;
  left: 5px;

}

.contactWrapper {
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: 1rem;
}

.printBtn {
  float: right;
  margin-bottom: 1rem;
  margin-right: 0 !important;
}

.printWrapper {
  margin-top: -5rem !important;
  margin-right: 3rem !important;
}

.printContent {

}

@media print {
  html, body {
    border: 1px solid white;
    height: 99% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  body * {
    visibility: hidden !important;
  }

  .printContent, .printContent * {
    visibility: visible !important;
    box-shadow: none !important;
  }

  .addressWrapper {
    position: absolute;
    top: 0;
    margin-bottom: 0 !important;
    padding: 1rem !important;
    transform: rotate(0) !important;
  }

  .date {
    margin: 0 !important;
    margin-top: 7rem !important;
    margin-bottom: 1rem !important;
  }

  .descriptionWrapper {
    margin: 14rem 0 0 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
  }

  .printLogoWrapper {
    width: 200px !important;
  }

  .printLogo {
    position: absolute;
    top: 1px;
    right: 1px;
    display: block !important;
    width: 200px !important;
  }

  .titleCenter {
    text-align: center;
  }

  .printBtn, .printWrapper {
    display: none !important;
  }

  .contactWrapper {
    margin-left: 0;
    margin-right: 1rem;
  }
}

.descriptionWrapper {
  padding: 3rem;
  box-shadow: var(--shadowLight);
  max-width: 750px;
  margin-left: auto;
  margin-top: -12rem;
  margin-bottom: 2rem;

  @media only screen and(max-width: 1024px) {
    margin-top: 0;
    box-shadow: none;
    padding: 0.5rem;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .titleWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  h3 {
    position: relative;
    display: inline-block;
    margin-bottom: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80%;
      height: 10px;
      background-color: var(--jsyellowlight);
      z-index: -1;
    }
  }

  * {
    text-align: left;
  }
}

.date {
  font-weight: bold;
  text-align: right;
  font-size: 1rem;
  color: var(--grey700);
  margin-bottom: 8rem;

  @media only screen and(max-width: 1024px) {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

.details {
  padding: 2px;
  padding-left: 1rem;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40px;
    background-color: var(--jsyellowlight);
    z-index: -1;
  }

  .flex {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .detailHead {
    display: inline-block;
    min-width: 150px;
    width: 150px;
    font-weight: bold;

    @media only screen and(max-width: 1024px) {
      display: block;
    }
  }
}