.block {
  display: block;
}

.none {
  display: none;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.wfull {
  width: 100%;
  padding: 0.5rem;
  flex: 1 1 300px;
  position: relative;
}

.w50 {
  width: 50%;
  padding: 0.5rem;
  flex: 1 1 200px;
  position: relative;
}

.w33 {
  width: 33.33333%;
  padding: 0.5rem;
  flex: 1 1 150px;
  position: relative;
}

.w25 {
  width: 25%;
  padding: 0.5rem;
  flex: 1 1 150px;
  position: relative;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.itemsCenter {
  align-items: center;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mr0 {
  margin-right: 0;
}

.container {
  max-width: 1000px;
  padding: 0.5rem;
  margin: auto;
}

.background {
  /*padding-bottom: 3rem;
  width: 100%;
  min-height: 100vh;*/
  background-repeat: repeat-y;
  background-size: 100%;
}

@media print {
  .container, .background {
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 99% !important;
    min-height: 0 !important;
  }
}